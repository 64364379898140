import { dayDiff } from '@spotahome/landlord-panel-ui-library/src/utils/dates';

import { now } from '../utils/date';

import {
  UNIT_BILLS_EDITED,
  UNIT_CALL_US_CLICKED,
  UNITS_UNPUBLISH,
  NAVIGATE_EDIT_LISTING_SECTION,
  CONTACT_FORM_LINK_VISITED,
  UNIT_STATS_BOOKING_LEADS_CLICKED,
  UNIT_STATS_PROPERTY_VIEWS_CLICKED,
  UNIT_RULES_EDITED,
  UNIT_PROPERTY_DETAILS_EDITED,
  PAGE_EXIT,
  UNIT_INTEREST_VIEW_PROFILE_CLICKED,
  DISCOVER_PLANS_CLICKED,
  DISCOVER_PLANS_MODAL_SHOWN,
  PLANS_CONTACT_CLICK,
  PLANS_UPGRADE_CLICK,
  PLANS_UPGRADE_CONFIRMATION_CLICK,
  DEFAULT_LANDLORD_PLAN_UPDATED,
  UNIT_OCCUPANCIES_ICAL_EDITED,
  PROPERTY_PHOTO_SAVED,
  PROPERTY_PHOTO_CATEGORY_MODIFIED,
  PROPERTY_PHOTO_ADDED,
  PROPERTY_PHOTO_DELETED,
  PROPERTY_PHOTO_SET_AS_COVER,
  PROPERTY_PHOTO_ACTIONS_DROPDOWN_CLICKED,
  PROPERTY_PHOTO_ORDER_CHANGED,
  PROPERTY_PHOTO_UPLOADER_CLICKED,
  PROPERTY_PHOTO_TIPS_SHOWN,
  PROPERTY_PHOTO_TIPS_CLOSED,
  PROPERTY_TYPE_CHANGE_SUBMITTED,
  UNIT_STATS_VIEWS_LOADED,
  UNIT_STATS_INTEREST_LOADED,
  ONBOARDING_TOOLTIP_ACKNOWLEDGED,
  READ_MORE_COMMISSION_INFO_CLICKED,
  PRICE_SUGGESTION_TOOLTIP_IS_SHOWN,
  PROPERTY_SOURCE_SUBMITTED,
  PROPERTY_SOURCE_SELECTED,
  IMPORT_LISTING_BACK_BUTTON_CLICKED,
  IMPORT_LISITNG_ADD_ANOTHER_CLICKED,
  IMPORT_LISTING_SUBMITTED,
  IMPORT_LISTING_SUBMITTED_ERROR,
  IMPORT_LISTING_BACK_TO_LISTING_CLICKED,
  HELP_LINK_CLICK_SELF_SERVICE,
  SAVE_AND_EXIT_LINK_CLICK_SELF_SERVICE,
  BOOKING_TOO_EARLY_WAS_CHANGED,
  MIN_MAX_STAY_WAS_CHANGED,
  UNIT_SEND_INTEREST_BOOKING_MODAL_CLOSED,
  UNIT_SEND_INTEREST_SUCCESS_MODAL_CLOSED,
  UNIT_SEND_INTEREST_MODAL_CLOSED,
  UNIT_SEND_INTEREST_ERROR_MODAL_CLOSED,
  UNIT_SEND_INTEREST_CLICKED,
  UNIT_SEND_INTEREST_CHECK_OCCUPANCIES_MODAL_BUTTON_CLICKED,
  UPDATE_ICAL_SYNCHRONIZATION,
  UPDATE_ICAL_SYNCHRONIZATION_SUCCESS,
  UPDATE_ICAL_SYNCHRONIZATION_ERROR,
  COPY_ICAL_LINK,
  APPLY_NO_DEPOSIT_SELF_SERVICE_SUGGESTION,
  MONTHLY_PRICE_ADD_LISTING_SUGGESTION_APPLIED,
  NO_SECURITY_DEPOSIT_ADD_LISTING_SUGGESTION_APPLIED,
  ALL_BILLS_INCLUDED_ADD_LISTING_APPLIED
} from '../lib/segment/events';

import {
  selectType,
  selectCityId,
  selectAvailableFrom,
  selectBedroomAvailableFrom,
  selectUnitsRentable,
  selectId,
  selectBills,
  selectDetails,
  selectSkipPhotos,
  isPublishable
} from '../domain/state/selectors';

import { isWhole } from '../domain/model/PropertyType';

import BillOptions from '../domain/model/BillOptions';

import {
  ADD_LISTING_TAG,
  ADD_LISTING_BILLS_VALIDATION_ERROR_TAG,
  EDIT_PROPERTY_SAVED_CHANGES,
  EDIT_PROPERTY_ERROR,
  EDIT_PROPERTY_CANCELLED_CHANGES,
  EDIT_PROPERTY_UNIT_SEASONAL_PRICE_EDITED,
  EDIT_PROPERTY_UNIT_PRICE_EDITED,
  EDIT_PROPERTY_UNIT_OCCUPANCY_CALENDAR_EDITED,
  EDIT_PROPERTY_UNIT_OCCUPANCIES_UPDATED,
  EDIT_PROPERTY_UNIT_OCCUPANCY_ADDED,
  EDIT_PROPERTY_UNIT_OCCUPANCY_REMOVED,
  EDIT_PROPERTY_UNIT_OCCUPANCY_SELECTED_YEAR_CHANGE,
  EDIT_PROPERTY_UNIT_OCCUPANCY_TOOLTIP_SHOWN,
  EDIT_PROPERTY_UNIT_OCCUPANCY_CANCELLATION_REQUESTED,
  EDIT_PROPERTY_UNIT_OCCUPANCY_DATES_SELECTED,
  CREATE_NEW_OFFER_FORM_SUBMITTED,
  OFFER_CREATED,
  CREATE_NEW_OFFER_BUTTON_CLICKED
} from './hotjar';

const WIFI = 'wifi';
const INTERNET = 'internet';

class Tracking {
  constructor({ segment, hotjar }) {
    this.segment = segment;
    this.hotjar = hotjar;
  }

  static parseUnitStayRules = (unitStayRules = {}) =>
    Object.entries(unitStayRules).reduce(
      (
        acc,
        [rentableUnitId, { bookingTooEarlyDays, minStay, maxStay }],
        index
      ) => ({
        ...acc,
        [index]: {
          rentableUnitId,
          bookingTooEarlyDays,
          minStay,
          maxStay
        }
      }),
      {}
    );

  identifyUser({ user, landlord = {}, propertiesSummary = {} }) {
    this.segment.identifyUser(user, {
      published_ru: propertiesSummary.totalPublishedUnits,
      city_ids: propertiesSummary.cities,
      landlord_type: landlord.type
    });
  }

  pageChanged() {
    this.segment.trackPageChanged();
  }

  flowLoaded() {
    this.hotjar.recordTag([ADD_LISTING_TAG]);
  }

  exitFlowClicked({ property, user, step }) {
    this.segment.trackEvent('ExitFlowClicked', user, {
      last_step: step,
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property)
    });
  }

  propertySourceSubmitted({ source, user }) {
    this.hotjar.recordTag([PROPERTY_SOURCE_SUBMITTED]);
    this.segment.trackEvent(PROPERTY_SOURCE_SUBMITTED, user, {
      source
    });
  }

  propertySourceSelected({ source, user }) {
    this.hotjar.recordTag([PROPERTY_SOURCE_SELECTED]);
    this.segment.trackEvent(PROPERTY_SOURCE_SELECTED, user, {
      source
    });
  }

  importListingBackButtonClicked({ user }) {
    this.hotjar.recordTag([IMPORT_LISTING_BACK_BUTTON_CLICKED]);
    this.segment.trackEvent(IMPORT_LISTING_BACK_BUTTON_CLICKED, user, {});
  }

  importListingAddAnotherClicked({ user }) {
    this.hotjar.recordTag([IMPORT_LISITNG_ADD_ANOTHER_CLICKED]);
    this.segment.trackEvent(IMPORT_LISITNG_ADD_ANOTHER_CLICKED, user, {});
  }

  importListingSubmitted({ listingsCount, user }) {
    this.hotjar.recordTag([IMPORT_LISTING_SUBMITTED]);
    this.segment.trackEvent(IMPORT_LISTING_SUBMITTED, user, {
      listingsCount
    });
  }

  importListingSubmittedError({ user, error }) {
    if (window.newrelic) {
      window.newrelic.noticeError(error, { user });
    }
    this.hotjar.recordTag([IMPORT_LISTING_SUBMITTED_ERROR]);
    this.segment.trackEvent(IMPORT_LISTING_SUBMITTED_ERROR, user, {
      error: error.message
    });
  }

  importListingBackToListingClicked({ user }) {
    this.hotjar.recordTag([IMPORT_LISTING_BACK_TO_LISTING_CLICKED]);
    this.segment.trackEvent(IMPORT_LISTING_BACK_TO_LISTING_CLICKED, user, {});
  }

  propertyTypeSubmitted({ property, user }) {
    this.segment.trackEvent('PropertyTypeSubmitted', user, {
      property_id: selectId(property),
      property_type: selectType(property)
    });
  }

  propertyAmenitiesSubmitted({ property, user }) {
    this.segment.trackEvent('PropertyAmenitiesSubmitted', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property)
    });
  }

  propertyFeaturesSubmitted({ property, user }) {
    this.segment.trackEvent('PropertyFeaturesSubmitted', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property)
    });
  }

  propertyAddressSubmitted({ property, user }) {
    this.segment.trackEvent('PropertyAddressSubmitted', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property)
    });
  }

  propertyDetailsSubmitted({ property, user }) {
    let isStudio = 'na';

    if (isWhole(selectType(property))) {
      isStudio = selectDetails(property).studioDistribution ? 'yes' : 'no';
    }

    this.segment.trackEvent('PropertyDetailsSubmitted', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      is_studio: isStudio,
      property_location: selectCityId(property)
    });
  }

  propertyLocationSubmitted({ property, user } = {}) {
    this.segment.trackEvent('PropertyLocationSubmitted', user, {
      property_type: selectType(property),
      property_location: selectCityId(property)
    });
  }

  bedroomAvailabilitySubmitted({ user, property, bedroomNumber } = {}) {
    this.segment.trackEvent('RoomAvailabilitySubmitted', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property),
      room_number: bedroomNumber,
      total_rooms: selectUnitsRentable(property),
      availability_added: selectBedroomAvailableFrom(property, bedroomNumber)
    });
  }

  allBillsIncludedToggleEnabled({ user, property, value }) {
    this.segment.trackEvent('AllBillsIncludedToggleEnabled', user, {
      property_type: selectType(property),
      property_location: selectCityId(property),
      toggleEnabled: value ? 'yes' : 'no'
    });
  }

  listingBillsDetailsSubmitted({ user, property } = {}) {
    const billsDetailsOf = aProperty =>
      selectBills(aProperty).reduce((prev, { name, value, additionalInfo }) => {
        const key = name === WIFI ? INTERNET : name;

        return {
          ...prev,
          [`${key}_value`]: value,
          [`${key}_amount`]: additionalInfo
        };
      }, {});

    const allIncluded = selectBills(property).every(
      bill => bill.value === BillOptions.INCLUDED
    );

    this.segment.trackEvent('ListingBillsDetailsSubmitted', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property),
      all_included: allIncluded ? 'yes' : 'no',
      ...billsDetailsOf(property)
    });
  }

  billsValidationError() {
    this.hotjar.recordTag([ADD_LISTING_BILLS_VALIDATION_ERROR_TAG]);
  }

  listingAvailabilitySubmitted({ user, property } = {}) {
    this.segment.trackEvent('ListingAvailabilitySubmitted', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property),
      availability_added: selectAvailableFrom(property)
    });
  }

  listingRentSubmitted({ property, user }) {
    this.segment.trackEvent('ListingPriceDetailsSubmitted', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property)
    });
  }

  listingPhotosChoiceSubmitted({ property, user }) {
    this.segment.trackEvent('ListingPhotosChoiceSubmitted', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property),
      skip_photos: selectSkipPhotos(property)
    });
  }

  bedroomCloned({ property, fromBedroomNumber, toBedroomNumber, user } = {}) {
    this.segment.trackEvent('RoomCloned', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property),
      from_room_number: fromBedroomNumber,
      to_room_number: toBedroomNumber
    });
  }

  bedroomDetailsSubmitted({ user, property, bedroomNumber } = {}) {
    this.segment.trackEvent('RoomDetailsSubmitted', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property),
      total_rooms: selectUnitsRentable(property),
      room_number: bedroomNumber
    });
  }

  bedroomAmenitiesSubmitted({ user, property, bedroomNumber } = {}) {
    this.segment.trackEvent('RoomAmenitiesSubmitted', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property),
      room_number: bedroomNumber,
      total_rooms: selectUnitsRentable(property)
    });
  }

  bedroomPriceDetailsSubmitted({ property, user, bedroomNumber }) {
    this.segment.trackEvent('RoomPriceDetailsSubmitted', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property),
      room_number: bedroomNumber,
      total_rooms: selectUnitsRentable(property)
    });
  }

  listingRulesSubmitted({ property, user } = {}) {
    this.segment.trackEvent('ListingRulesSubmitted', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property)
    });
  }

  homeCheckerOptionSubmitted({ property, user, visitScheduled }) {
    this.segment.trackEvent('HCOptionSubmitted', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property),
      visit_scheduled: visitScheduled
    });
  }

  homeCheckerExplainerClicked({ property, user }) {
    this.segment.trackEvent('HCExplainerClicked', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property)
    });
  }

  homeCheckerDatesExpanded(eventName, { property, user, flowType }) {
    this.segment.trackEvent(eventName, user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property),
      flow_type: flowType
    });
  }

  homeCheckerDateSubmitted({ property, user, selectedSlot }) {
    this.segment.trackEvent('HCDateSubmitted', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property),
      time_to_visit: dayDiff(selectedSlot, now())
    });
  }

  homeCheckerSuccessViewed({ property, user }) {
    this.segment.trackEvent('HCSuccessViewed', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property)
    });
  }

  homeCheckerExplanatoryScreenClicked({ property, user }) {
    this.segment.trackEvent('PSFlowExplanatoryScreenClicked', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property)
    });
  }

  homeCheckerNotesClicked({ property, user }) {
    this.segment.trackEvent('PSFlowNotesForHCClicked', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property)
    });
  }

  homeCheckerReturnListingsClicked({ property, user }) {
    this.segment.trackEvent('PSFlowFinishedReturnListings', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property)
    });
  }

  homeCheckerExitClicked({ property, user }) {
    this.segment.trackEvent('PSFlowExitClicked', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property)
    });
  }

  rescheduleHomeCheckerClicked({ property, user, sourceList }) {
    const { city: cityId } = property.location;

    this.segment.trackEvent('ReschedulePhotoSessionClicked', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId({ cityId }),
      source_list: sourceList
    });
  }

  listingFinished({ property, user }) {
    const rentableUnitsNumber =
      selectType(property) === 'whole' ? 1 : selectUnitsRentable(property);
    this.segment.trackEvent('ListingFinished', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property),
      listing_status: isPublishable(property) ? 'published' : 'unpublished',
      rentable_units_quantity: rentableUnitsNumber
    });
  }

  propertyPhotosSubmitted({ property, user, quantity } = {}) {
    this.segment.trackEvent('ListingPhotosSubmitted', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property),
      quantity,
      photo_type: 'property'
    });
  }

  uploadPhotosData({ property, user, size, mimeType, width, height } = {}) {
    this.segment.trackEvent('ListingPhotosUploadData', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property),
      size,
      mimeType,
      width,
      height
    });
  }

  propertyBeforePhotosSubmitted({ property, user } = {}) {
    this.segment.trackEvent('ListingBeforePhotosSubmitted', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property)
    });
  }

  bedroomPhotosSubmitted({ property, user, quantity } = {}) {
    this.segment.trackEvent('ListingPhotosSubmitted', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property),
      quantity,
      photo_type: 'room'
    });
  }

  propertyPhotosRemoved({ property, user } = {}) {
    this.segment.trackEvent('ListingPhotosRemoved', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property),
      photo_type: 'property'
    });
  }

  bedroomPhotosRemoved({ property, user } = {}) {
    this.segment.trackEvent('ListingPhotosRemoved', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property),
      photo_type: 'room'
    });
  }

  propertyUnfurnishedSelected({ property, user } = {}) {
    this.segment.trackEvent('PropertyUnfurnishedSelected', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property)
    });
  }

  addPhotosClicked({ property, user } = {}) {
    this.segment.trackEvent('AddPhotosClicked', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property)
    });
  }

  photoTipsClicked({ property, user } = {}) {
    this.segment.trackEvent('PhotoTipsClicked', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property)
    });
  }

  minStayValidationError({ property, user } = {}) {
    this.segment.trackEvent('MinStayValidationError', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property)
    });
  }

  addressValidationError({ property, user, type } = {}) {
    this.segment.trackEvent('AddressValidationError', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property),
      error_type: type
    });
  }

  propertyAddressCityNotSupported({ cityId, address, user }) {
    this.segment.trackEvent('PropertyAddressNotSupportedCity', user, {
      cityId,
      address
    });
  }

  listingLegalSubmitted({ property, user } = {}) {
    this.segment.trackEvent('ListingLegalSubmitted', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property)
    });
  }

  legalValidationError({ property, user, type } = {}) {
    this.segment.trackEvent('LegalValidationError', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property),
      error_type: type
    });
  }

  homeCheckerErrorThrown({ property, user, selectedSlot } = {}) {
    this.segment.trackEvent('HCDateErrorThrown', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property),
      time_to_visit: dayDiff(selectedSlot, now())
    });
  }

  addAnotherListingCTAClicked = ({ property, user } = {}) => {
    this.segment.trackEvent('AddAnotherListingCTAClicked', user, {
      property_id: selectId(property),
      property_type: selectType(property),
      property_location: selectCityId(property)
    });
  };

  createOfferButtonClicked = ({ user }) => {
    this.segment.trackEvent(CREATE_NEW_OFFER_BUTTON_CLICKED, user);
    this.hotjar.recordTag([CREATE_NEW_OFFER_BUTTON_CLICKED]);
  };

  createOfferFormSubmitted = ({ user }) => {
    this.segment.trackEvent(CREATE_NEW_OFFER_FORM_SUBMITTED, user);
    this.hotjar.recordTag([CREATE_NEW_OFFER_FORM_SUBMITTED]);
  };

  offerCreated = ({
    user,
    discountType,
    discountValue,
    minStay,
    maxStay,
    appliedToAll
  }) => {
    this.segment.trackEvent(OFFER_CREATED, user, {
      discount_type: discountType,
      discount_value: discountValue,
      min_stay: minStay,
      max_stay: maxStay,
      applied_to_all: appliedToAll
    });
    this.hotjar.recordTag([OFFER_CREATED]);
  };

  editListingSubmitted = ({ id, user, section }) => {
    this.segment.trackEvent(EDIT_PROPERTY_SAVED_CHANGES, user, {
      ru_id: id,
      property_id: id,
      section
    });
    this.hotjar.recordTag([EDIT_PROPERTY_SAVED_CHANGES]);
  };

  unpublishUnitsSubmitted = ({ id, user, unitsToUnpublish }) => {
    this.segment.trackEvent(UNITS_UNPUBLISH, user, {
      ru_id: id,
      property_id: id,
      unitsToUnpublish
    });
  };

  navigateToEditListingSection = ({ id, user, sectionId }) => {
    this.hotjar.recordTag([NAVIGATE_EDIT_LISTING_SECTION]);
    this.segment.trackEvent(NAVIGATE_EDIT_LISTING_SECTION, user, {
      ru_id: id,
      property_id: id,
      sectionId
    });
  };

  contactFormLinkVisited = ({ sectionId, user }) => {
    this.segment.trackEvent(CONTACT_FORM_LINK_VISITED, user, {
      sectionId
    });
  };

  editListingSubmitFailed = ({ id, user, error, formValues }) => {
    if (window.newrelic) {
      window.newrelic.noticeError(error, { user, propertyId: id, formValues });
    }
    this.hotjar.recordTag([EDIT_PROPERTY_ERROR]);
    this.segment.trackEvent(EDIT_PROPERTY_ERROR, user, {
      ru_id: id,
      property_id: id,
      error: error.message
    });
  };

  editListingCancelled = () => {
    this.hotjar.recordTag([EDIT_PROPERTY_CANCELLED_CHANGES]);
  };

  editListingOccupanciesUpdated = ({
    id,
    user,
    rentableUnit,
    originalRentableUnit
  }) => {
    const newOccupancies = Object.entries(rentableUnit.occupancies ?? {}).map(
      ([rentableUnitId, occupancies]) =>
        JSON.stringify(
          occupancies.map(({ dates, note, id: occupancyId }) => ({
            rentableUnitId,
            dates,
            note,
            occupancyId
          }))
        )
    );

    const originalOccupancies = Object.entries(
      originalRentableUnit.occupancies ?? {}
    ).map(([rentableUnitId, occupancies]) =>
      JSON.stringify(
        occupancies.map(({ dates, note, id: occupancyId }) => ({
          rentableUnitId,
          dates,
          note,
          occupancyId
        }))
      )
    );

    this.segment.trackEvent(EDIT_PROPERTY_UNIT_OCCUPANCIES_UPDATED, user, {
      ru_id: id,
      property_id: id,
      newMaxStay: rentableUnit.maxStay,
      newMinStay: rentableUnit.minStay,
      originalMaxStay: originalRentableUnit.maxStay,
      originalMinStay: originalRentableUnit.minStay,
      newOccupancies,
      originalOccupancies,
      originalUnitStayRules: Tracking.parseUnitStayRules(
        originalRentableUnit?.unitStayRules
      ),
      newUnitStayRules: Tracking.parseUnitStayRules(rentableUnit?.unitStayRules)
    });
  };

  editListingOccupanciesICalUpdated = ({ id, user, from, to }) => {
    this.segment.trackEvent(UNIT_OCCUPANCIES_ICAL_EDITED, user, {
      ru_id: id,
      property_id: id,
      from: JSON.stringify(from),
      to: JSON.stringify(to)
    });
  };

  editListingFeaturesUpdated = (
    eventName,
    { features, defaultFeatures, id, user }
  ) => {
    try {
      const billChanges = features.reduce((acc, currentFeature) => {
        const originalBill =
          (defaultFeatures || []).find(it => it.name === currentFeature.name) ||
          {};

        if (
          originalBill.value !== currentFeature.value ||
          originalBill.additionalInfo !== currentFeature.additionalInfo
        ) {
          const key =
            currentFeature.name === WIFI ? INTERNET : currentFeature.name;
          return {
            ...acc,
            [`${key}_old_value`]: originalBill.value || null,
            [`${key}_old_amount`]: originalBill.additionalInfo || null,
            [`${key}_new_value`]: currentFeature.value || null,
            [`${key}_new_amount`]: currentFeature.additionalInfo || null
          };
        }

        return acc;
      }, {});

      this.segment.trackEvent(eventName, user, {
        ru_id: id,
        property_id: id,
        ...billChanges
      });
    } catch (error) {
      console.error(error);
    }
  };

  editListingBillsUpdated = ({ bills, defaultBills, id, user }) => {
    this.editListingFeaturesUpdated(UNIT_BILLS_EDITED, {
      features: bills,
      defaultFeatures: defaultBills,
      id,
      user
    });
  };

  editListingRulesUpdated = ({ rules, defaultRules, id, user }) => {
    this.editListingFeaturesUpdated(UNIT_RULES_EDITED, {
      features: rules,
      defaultFeatures: defaultRules,
      id,
      user
    });
  };

  editListingDetailsUpdated = ({ details, defaultDetails, id, user }) => {
    this.editListingFeaturesUpdated(UNIT_PROPERTY_DETAILS_EDITED, {
      features: details,
      defaultFeatures: defaultDetails,
      id,
      user
    });
  };

  editListingSeasonalPriceUpdated = () => {
    this.hotjar.recordTag([EDIT_PROPERTY_UNIT_SEASONAL_PRICE_EDITED]);
  };

  editListingFixedPriceUpdated = () => {
    this.hotjar.recordTag([EDIT_PROPERTY_UNIT_PRICE_EDITED]);
  };

  editListingOccupancyDatesChanged = () => {
    this.hotjar.recordTag([EDIT_PROPERTY_UNIT_OCCUPANCY_CALENDAR_EDITED]);
  };

  editListingOccupancyAdded = ({ user, id }) => {
    this.hotjar.recordTag([EDIT_PROPERTY_UNIT_OCCUPANCY_ADDED]);
    this.segment.trackEvent(EDIT_PROPERTY_UNIT_OCCUPANCY_ADDED, user, {
      ru_id: id,
      property_id: id
    });
  };

  editListingOccupancyRemoved = ({ user, id }) => {
    this.hotjar.recordTag([EDIT_PROPERTY_UNIT_OCCUPANCY_REMOVED]);
    this.segment.trackEvent(EDIT_PROPERTY_UNIT_OCCUPANCY_REMOVED, user, {
      ru_id: id,
      property_id: id
    });
  };

  editListingOccupancyYearSelected = ({ user, id }) => {
    this.hotjar.recordTag([EDIT_PROPERTY_UNIT_OCCUPANCY_SELECTED_YEAR_CHANGE]);
    this.segment.trackEvent(
      EDIT_PROPERTY_UNIT_OCCUPANCY_SELECTED_YEAR_CHANGE,
      user,
      {
        ru_id: id,
        property_id: id
      }
    );
  };

  editListingOccupancyTooltipShown = ({
    user,
    id,
    occupancyType,
    occupancyDates
  }) => {
    this.hotjar.recordTag([EDIT_PROPERTY_UNIT_OCCUPANCY_TOOLTIP_SHOWN]);
    this.segment.trackEvent(EDIT_PROPERTY_UNIT_OCCUPANCY_TOOLTIP_SHOWN, user, {
      ru_id: id,
      property_id: id,
      occupancy_type: occupancyType,
      occupancy_dates: occupancyDates
    });
  };

  editListingOccupancyDatesSelected = ({ user, id, dateFrom, dateTo }) => {
    this.hotjar.recordTag([EDIT_PROPERTY_UNIT_OCCUPANCY_DATES_SELECTED]);
    this.segment.trackEvent(EDIT_PROPERTY_UNIT_OCCUPANCY_DATES_SELECTED, user, {
      ru_id: id,
      property_id: id,
      date_from: dateFrom,
      date_to: dateTo
    });
  };

  editListingOccupancyCancellationRequested = () => {
    this.hotjar.recordTag([
      EDIT_PROPERTY_UNIT_OCCUPANCY_CANCELLATION_REQUESTED
    ]);
  };

  editListingBookingInfoRequested = ({ bookingId, id, user }) => {
    this.segment.trackEvent(UNIT_CALL_US_CLICKED, user, {
      ru_id: id,
      booking_id: bookingId
    });
  };

  listingServicesExpensesSubmitted({
    servicesAndExpenses,
    updatedServicesAndExpenses,
    user,
    propertyId
  } = {}) {
    try {
      const servicesAndExpensesChanges = updatedServicesAndExpenses.reduce(
        (acc, currentServicesAndExpenses) => {
          const originalServicesAndExpenses =
            (servicesAndExpenses || []).find(
              it => it.name === currentServicesAndExpenses.name
            ) || {};

          if (
            originalServicesAndExpenses.value !==
              currentServicesAndExpenses.value ||
            originalServicesAndExpenses.additionalInfo !==
              currentServicesAndExpenses.additionalInfo
          ) {
            const key = currentServicesAndExpenses.name;
            return {
              ...acc,
              [`${key}_old_value`]: originalServicesAndExpenses.value || null,
              [`${key}_old_additional_info`]:
                originalServicesAndExpenses.additionalInfo || null,
              [`${key}_new_value`]: currentServicesAndExpenses.value || null,
              [`${key}_new_additional_info`]:
                currentServicesAndExpenses.additionalInfo || null
            };
          }

          return acc;
        },
        {}
      );

      this.segment.trackEvent('ListingServicesExpensesSubmitted', user, {
        ru_id: propertyId,
        property_id: propertyId,
        ...servicesAndExpensesChanges
      });
    } catch (error) {
      console.error(error);
    }
  }

  listingScoreShowTooltip({ user, score }) {
    this.segment.trackEvent('ListingScoreShowTooltip', user, {
      score: {
        label: score?.label,
        value: score?.value
      }
    });
  }

  listingScoreFormClick({ user, score }) {
    this.segment.trackEvent('ListingScoreFormClick', user, {
      score: {
        label: score?.label,
        value: score?.value
      }
    });
  }

  bookingLeadsInfoClicked = ({ user, id, count }) => {
    this.segment.trackEvent(UNIT_STATS_BOOKING_LEADS_CLICKED, user, {
      ru_id: id,
      count
    });
  };

  viewersTagLoaded({ user }) {
    this.segment.trackEvent(UNIT_STATS_VIEWS_LOADED, user);
  }

  viewersInfoClicked = ({ user, id, count }) => {
    this.segment.trackEvent(UNIT_STATS_PROPERTY_VIEWS_CLICKED, user, {
      ru_id: id,
      count
    });
  };

  interestsTagLoaded({ user }) {
    this.segment.trackEvent(UNIT_STATS_INTEREST_LOADED, user);
  }

  interestViewProfileClick({ user, unitId, bookingLeadId }) {
    this.segment.trackEvent(UNIT_INTEREST_VIEW_PROFILE_CLICKED, user, {
      ru_id: unitId,
      booking_lead_id: bookingLeadId
    });
    this.hotjar.recordTag([UNIT_INTEREST_VIEW_PROFILE_CLICKED]);
  }

  sendInterestClicked({ user, unitId, bookingLeadId }) {
    this.segment.trackEvent(UNIT_SEND_INTEREST_CLICKED, user, {
      ru_id: unitId,
      booking_lead_id: bookingLeadId
    });
    this.hotjar.recordTag([UNIT_SEND_INTEREST_CLICKED]);
  }

  sendInterestBookingModalClosed({ user, unitId, bookingLeadId }) {
    this.segment.trackEvent(UNIT_SEND_INTEREST_BOOKING_MODAL_CLOSED, user, {
      ru_id: unitId,
      booking_lead_id: bookingLeadId
    });
    this.hotjar.recordTag([UNIT_SEND_INTEREST_BOOKING_MODAL_CLOSED]);
  }

  sendInterestErrorModalClosed({ user, unitId, bookingLeadId }) {
    this.segment.trackEvent(UNIT_SEND_INTEREST_ERROR_MODAL_CLOSED, user, {
      ru_id: unitId,
      booking_lead_id: bookingLeadId
    });
    this.hotjar.recordTag([UNIT_SEND_INTEREST_ERROR_MODAL_CLOSED]);
  }

  sendInterestSuccessModalClosed({ user, unitId, bookingLeadId }) {
    this.segment.trackEvent(UNIT_SEND_INTEREST_SUCCESS_MODAL_CLOSED, user, {
      ru_id: unitId,
      booking_lead_id: bookingLeadId
    });
    this.hotjar.recordTag([UNIT_SEND_INTEREST_SUCCESS_MODAL_CLOSED]);
  }

  sendInteresModalClosed({ user, unitId, bookingLeadId }) {
    this.segment.trackEvent(UNIT_SEND_INTEREST_MODAL_CLOSED, user, {
      ru_id: unitId,
      booking_lead_id: bookingLeadId
    });
    this.hotjar.recordTag([UNIT_SEND_INTEREST_MODAL_CLOSED]);
  }

  sendInteresCheckOccupanciesModalButtonClicked({
    user,
    unitId,
    bookingLeadId
  }) {
    this.segment.trackEvent(
      UNIT_SEND_INTEREST_CHECK_OCCUPANCIES_MODAL_BUTTON_CLICKED,
      user,
      {
        ru_id: unitId,
        booking_lead_id: bookingLeadId
      }
    );
    this.hotjar.recordTag([
      UNIT_SEND_INTEREST_CHECK_OCCUPANCIES_MODAL_BUTTON_CLICKED
    ]);
  }

  pageExit = ({ user, id, href }) => {
    this.segment.trackEvent(PAGE_EXIT, user, {
      id,
      href
    });
    this.hotjar.recordTag([PAGE_EXIT]);
  };

  listingScoreOptimizeButtonClick({ user, unitId, score }) {
    this.segment.trackEvent('ListingScoreOptimizeButtonClick', user, {
      unitId,
      score: {
        label: score?.label,
        value: score?.value
      }
    });
  }

  discoverPlansButtonClick({ user }) {
    this.segment.trackEvent(DISCOVER_PLANS_CLICKED, user, {});
  }

  discoverPlansModalShown({ user }) {
    this.segment.trackEvent(DISCOVER_PLANS_MODAL_SHOWN, user, {});
  }

  plansContactClick({ user, planType }) {
    this.segment.trackEvent(PLANS_CONTACT_CLICK, user, {
      plan_type: planType
    });
  }

  defaultLandlordPlanUpdated({ user, planType }) {
    this.hotjar.recordTag([DEFAULT_LANDLORD_PLAN_UPDATED]);
    this.segment.trackEvent(DEFAULT_LANDLORD_PLAN_UPDATED, user, {
      plan_type: planType
    });
  }

  plansUpgradeClick({ user, fromPlan, toPlan }) {
    this.hotjar.recordTag([PLANS_UPGRADE_CLICK]);
    this.segment.trackEvent(PLANS_UPGRADE_CLICK, user, {
      fromPlan,
      toPlan,
      isAgent: !!user?.impersonatedAs?.id
    });
  }

  plansUpgradeConfirmationClick({ user, fromPlan, toPlan }) {
    this.hotjar.recordTag([PLANS_UPGRADE_CONFIRMATION_CLICK]);
    this.segment.trackEvent(PLANS_UPGRADE_CONFIRMATION_CLICK, user, {
      fromPlan,
      toPlan,
      isAgent: !!user?.impersonatedAs?.id
    });
  }

  trackPropertyPhotoTipsShown = ({ propertyId, user }) => {
    this.segment.trackEvent(PROPERTY_PHOTO_TIPS_SHOWN, user, {
      property_id: propertyId
    });
  };

  trackPropertyPhotoTipsClosed = ({ propertyId, user }) => {
    this.segment.trackEvent(PROPERTY_PHOTO_TIPS_CLOSED, user, {
      property_id: propertyId
    });
  };

  trackPropertyPhotoSaved = ({ propertyId, user }) => {
    this.segment.trackEvent(PROPERTY_PHOTO_SAVED, user, {
      property_id: propertyId
    });
  };

  trackPropertyPhotoUploaderClicked = ({ propertyId, subsection, user }) => {
    this.segment.trackEvent(PROPERTY_PHOTO_UPLOADER_CLICKED, user, {
      property_id: propertyId,
      subsection
    });
  };

  trackPropertyPhotoCategoryChanged = ({ propertyId, subsection, user }) => {
    this.segment.trackEvent(PROPERTY_PHOTO_CATEGORY_MODIFIED, user, {
      property_id: propertyId,
      subsection
    });
  };

  trackPropertyPhotoAdded = ({ propertyId, subsection, user }) => {
    this.segment.trackEvent(PROPERTY_PHOTO_ADDED, user, {
      property_id: propertyId,
      subsection
    });
  };

  trackPropertyPhotoDeleted = ({ propertyId, subsection, user }) => {
    this.segment.trackEvent(PROPERTY_PHOTO_DELETED, user, {
      property_id: propertyId,
      subsection
    });
  };

  trackPropertyPhotoSetAsCover = ({ propertyId, subsection, user }) => {
    this.segment.trackEvent(PROPERTY_PHOTO_SET_AS_COVER, user, {
      property_id: propertyId,
      subsection
    });
  };

  trackPropertyPhotoOrderChanged = ({ propertyId, user, sectionChanged }) => {
    this.segment.trackEvent(PROPERTY_PHOTO_ORDER_CHANGED, user, {
      property_id: propertyId,
      sectionChanged
    });
  };

  trackPropertyPhotoActionsDropdownClicked = ({
    propertyId,
    subsection,
    user
  }) => {
    this.segment.trackEvent(PROPERTY_PHOTO_ACTIONS_DROPDOWN_CLICKED, user, {
      property_id: propertyId,
      subsection
    });
  };

  recordExperimentTagInHotjar(experimentName, experimentVariant) {
    this.hotjar.recordTag(`${experimentName}_${experimentVariant}`);
  }

  trackPropertyTypeChangeSubmitted({ propertyId, type, user }) {
    this.hotjar.recordTag([PROPERTY_TYPE_CHANGE_SUBMITTED]);
    this.segment.trackEvent(PROPERTY_TYPE_CHANGE_SUBMITTED, user, {
      property_id: propertyId,
      type,
      user
    });
  }

  trackOnboardingTooltipAcknowledged({ type, user }) {
    this.hotjar.recordTag([ONBOARDING_TOOLTIP_ACKNOWLEDGED]);
    this.segment.trackEvent(ONBOARDING_TOOLTIP_ACKNOWLEDGED, user, {
      type,
      user
    });
  }

  readMoreCommissionInfoClicked({ user, isOpen }) {
    this.hotjar.recordTag([READ_MORE_COMMISSION_INFO_CLICKED]);
    this.segment.trackEvent(READ_MORE_COMMISSION_INFO_CLICKED, user, {
      isOpen
    });
  }

  priceSuggestionTooltipIsShown({ user }) {
    this.hotjar.recordTag([PRICE_SUGGESTION_TOOLTIP_IS_SHOWN]);
    this.segment.trackEvent(PRICE_SUGGESTION_TOOLTIP_IS_SHOWN, user);
  }

  minMaxStayHasChanged(user, propertyId, { formData, prevFormData }) {
    this.hotjar.recordTag([MIN_MAX_STAY_WAS_CHANGED]);
    this.segment.trackEvent(MIN_MAX_STAY_WAS_CHANGED, user, {
      property_id: propertyId,
      unitStayRules: Tracking.parseUnitStayRules(formData.unitStayRules),
      prevUnitStayRules: Tracking.parseUnitStayRules(prevFormData.unitStayRules)
    });
  }

  bookingTooEarlyDaysHasChanged(user, propertyId, { formData, prevFormData }) {
    this.hotjar.recordTag([BOOKING_TOO_EARLY_WAS_CHANGED]);
    this.segment.trackEvent(BOOKING_TOO_EARLY_WAS_CHANGED, user, {
      property_id: propertyId,
      unitStayRules: Tracking.parseUnitStayRules(formData.unitStayRules),
      prevUnitStayRules: Tracking.parseUnitStayRules(prevFormData.unitStayRules)
    });
  }

  helpLinkClickedInSelfService({ user }) {
    this.hotjar.recordTag([HELP_LINK_CLICK_SELF_SERVICE]);
    this.segment.trackEvent(HELP_LINK_CLICK_SELF_SERVICE, user);
  }

  saveAndExitLinkClickedInSelfService({ user }) {
    this.hotjar.recordTag([SAVE_AND_EXIT_LINK_CLICK_SELF_SERVICE]);
    this.segment.trackEvent(SAVE_AND_EXIT_LINK_CLICK_SELF_SERVICE, user);
  }

  updateIcalSynchronization({ user, propertyId, isActive }) {
    this.segment.trackEvent(UPDATE_ICAL_SYNCHRONIZATION, user, {
      property_id: propertyId,
      isActive
    });
  }
  updateIcalSynchronizationSuccess({ user, listingId, isActive }) {
    this.segment.trackEvent(UPDATE_ICAL_SYNCHRONIZATION_SUCCESS, user, {
      listing_id: listingId,
      isActive
    });
  }
  updateIcalSynchronizationError({ user, listingId, isActive }) {
    this.segment.trackEvent(UPDATE_ICAL_SYNCHRONIZATION_ERROR, user, {
      listing_id: listingId,
      isActive
    });
  }
  copyIcalendarLink({ user, listingId }) {
    this.segment.trackEvent(COPY_ICAL_LINK, user, {
      listing_id: listingId
    });
  }

  applyNoDepositSelfServiceSuggestion({ user, propertyId }) {
    this.segment.trackEvent(APPLY_NO_DEPOSIT_SELF_SERVICE_SUGGESTION, user, {
      property_id: propertyId
    });
  }

  monthlyPriceAddListingSuggestionApplied({ user, propertyId }) {
    this.segment.trackEvent(
      MONTHLY_PRICE_ADD_LISTING_SUGGESTION_APPLIED,
      user,
      {
        property_id: propertyId
      }
    );
  }

  noSecurityDepositAddListingSuggestionApplied({ user, propertyId }) {
    this.segment.trackEvent(
      NO_SECURITY_DEPOSIT_ADD_LISTING_SUGGESTION_APPLIED,
      user,
      {
        property_id: propertyId
      }
    );
  }

  allBillsIncludedAddListingApplied({ user, propertyId }) {
    this.segment.trackEvent(ALL_BILLS_INCLUDED_ADD_LISTING_APPLIED, user, {
      property_id: propertyId
    });
  }
}

export default Tracking;
