import gql from 'graphql-tag';

export const GET_LANDLORD_INFO = gql`
  query getLandlordInfoQuery {
    landlord {
      id
      cityId
      type
      assignedAccountManager
      accountPlan
      canUpgradePlans
      planCommissions {
        plan
        commission
      }
      showContactPhoneNumber
      isLanlordAgentAtWorkingHours
      bookingContact {
        phone
      }
      stripe {
        verificationStatus
      }
    }
  }
`;
