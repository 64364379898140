import PropTypes from 'prop-types';
import { withLocales, compose, withUser, trans } from '@spotahome/soyuz/client';
import Tracking from '@spotahome/soyuz-tracking';
import { localesShape, userShape } from '@spotahome/soyuz/shapes';
import { withRouter, matchPath } from 'react-router';
import { useQuery } from '@apollo/react-hooks';

import {
  FullWidthLayout,
  routes
} from '@spotahome/landlord-panel-ui-library/src/containers/LandlordPanel';

import { getCourrentRoute } from '@spotahome/landlord-panel-ui-library/src/utils/url';
import { isPaidPlan } from '@spotahome/landlord-panel-ui-library/src/utils/plans';

import usePlansSection from '@spotahome/landlord-panel-ui-library/src/hooks/usePlansSection';

import Typography from '@spotahome/landlord-panel-ui-library/src/components/Typography/Typography';

import track from '../../tracking';

import {
  DIRECT_CONTACT_MOBILE_BUTTON_CLICKED,
  WHATSAPP_MOBILE_BUTTON_CLICKED
} from '../../lib/segment/events';

import { GET_LANDLORD_INFO } from '../../graphql/landlord/queries/landlord';

import {
  CHANGE_LANGUAGE_PANEL,
  LOG_OUT,
  BOOKING_MENU_SECTION_CLICKED
} from '../../lib/hotjar-events';
import landlordPanelClient from '../../api/landlord-panel-client';
import { redirectToLogin } from '../../lib/url-utils';
import { trackMenuSectionClicked } from '../../lib/segment/menu';
import { trackReferralClicked } from '../../lib/segment/referral';
import { trackLogoutClicked } from '../../lib/segment/auth';
import { handleBannerClicked } from '../../lib/segment/banners';
import withTranslations from '../../hoc/withTranslations';
import { GET_COUNT_LANDLORD_LISTINGS } from '../../graphql/properties/queries/propertiesByLandlord';
import { useListingsCountContext } from '../../context/listingsCountProvider';

const LandlordFullWidthPanelLayout = withLocales()(
  withTranslations(FullWidthLayout)
);

const whatsappLink = 'https://wa.me/34911983040';

const MenuComponent = ({
  // eslint-disable-next-line react/prop-types
  isTelephoneVisible,
  // eslint-disable-next-line react/prop-types
  isWorkingHours,
  // eslint-disable-next-line react/prop-types
  showWhatsappButton,
  ...props
}) => (
  <LandlordFullWidthPanelLayout
    {...props}
    directContactFeatureFlag
    whatsappLink={whatsappLink}
    isTelephoneVisible={isTelephoneVisible}
    isWorkingHours={isWorkingHours}
  />
);

const Layout = ({
  user,
  locales,
  location,
  onLanguageChange = null,
  children
}) => {
  const listingsCountContext = useListingsCountContext();
  const listingsCount = listingsCountContext
    ? listingsCountContext.listingsCount
    : null;

  const [plansSectionVisited] = usePlansSection();

  const title =
    listingsCount !== null ? (
      <Typography variant="HeadingL">
        {listingsCount} {trans('sahc.menu.route.properties')}
      </Typography>
    ) : null;

  const { loading: loadingLandlordInfo, data } = useQuery(GET_LANDLORD_INFO, {
    context: { uri: '/landlord-properties/graphql' }
  });

  const { loading: loadingCountListings, data: dataLandlordListings } =
    useQuery(GET_COUNT_LANDLORD_LISTINGS, {
      context: { uri: '/landlord-properties/graphql' }
    });

  const loading = loadingLandlordInfo || loadingCountListings;

  const totalListings =
    dataLandlordListings?.getLandlordListings?.totalListings;

  const hasAssignedManager = data?.landlord?.assignedAccountManager;

  const handleLanguageChange = locale => {
    Tracking.hotjar.recordTag([CHANGE_LANGUAGE_PANEL]);

    if (onLanguageChange) {
      return onLanguageChange(locale);
    }

    return landlordPanelClient
      .updateUserInfo({ locale })
      .then(() => window.location.reload())
      .catch(() => {
        // TODO: Show appropiate error message
      });
  };

  const handleLogout = () => {
    Tracking.hotjar.recordTag([LOG_OUT]);
    trackLogoutClicked(user, locales.current);
    redirectToLogin();
  };

  const handleSectionClick = (_, id) => {
    trackMenuSectionClicked(id, user, locales.current);
    if (id === routes.BOOKINGS.id) {
      Tracking.hotjar.recordTag([BOOKING_MENU_SECTION_CLICKED]);
    }
  };

  const handleReferralMoreInfoClick = () => {
    trackReferralClicked(user, locales.current);
  };

  const handleOnClickBanner = banner => {
    handleBannerClicked(user, locales.current, banner);
  };

  const handleOnPageExit = event => {
    const { href, id } = event.target;
    track.pageExit({ user, id, href });
  };

  const onClickSupport = event => {
    Tracking.segment.trackEvent(event, user, {
      user_id: user.id
    });
  };

  const currentRoute = getCourrentRoute(location.pathname, routes, matchPath);

  if (loadingLandlordInfo || loadingCountListings) {
    return <></>;
  }

  return (
    <MenuComponent
      user={user}
      onLogOutClick={handleLogout}
      onLanguageChange={handleLanguageChange}
      currentRoute={currentRoute}
      onSectionClick={handleSectionClick}
      onReferralMoreInfoClicked={handleReferralMoreInfoClick}
      showOffersMenuItem
      onClickBannerAppHeader={() => handleOnClickBanner('mobile_app_header')}
      onWhatsappClick={() => onClickSupport(WHATSAPP_MOBILE_BUTTON_CLICKED)}
      onDirectContactClick={() =>
        onClickSupport(DIRECT_CONTACT_MOBILE_BUTTON_CLICKED)
      }
      onPageExit={handleOnPageExit}
      showMenuSupportLinksSection
      showWhatsappButton={!loading && hasAssignedManager === 'unassigned'}
      isTelephoneVisible={data?.landlord?.showContactPhoneNumber}
      isWorkingHours={data?.landlord?.isLanlordAgentAtWorkingHours}
      canShowPlansSection={!loading && data?.landlord?.canUpgradePlans}
      highlightPlansSection={
        !isPaidPlan(data?.landlord?.accountPlan) && !plansSectionVisited
      }
      isLandlordVettingRestricted={
        !loading &&
        (data?.landlord?.stripe?.verificationStatus === 'Restricted' ||
          data?.landlord?.stripe?.verificationStatus === 'RestrictedSoon')
      }
      hideAllLinks={totalListings === 0}
      title={title}
    >
      {children}
    </MenuComponent>
  );
};

Layout.propTypes = {
  user: PropTypes.shape(userShape).isRequired,
  locales: PropTypes.shape(localesShape).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  onLanguageChange: PropTypes.func,
  children: PropTypes.node.isRequired
};

export default compose(withUser(), withLocales(), withRouter)(Layout);
