class InvalidValueError extends Error {
  constructor(message = 'invalid value', code, data) {
    super(message);
    this.name = 'InvalidValueError';
    this.code = code;
    this.data = data;
  }
}

export default InvalidValueError;
