import { useEffect } from 'react';
import { renderInDocumentWithRouter } from '@spotahome/soyuz/client';

import LandlordLandingContainer from './main';
import { trackLandlordLandingSectionViewed } from './utils/track';

const LandingPage = () => {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
    trackLandlordLandingSectionViewed('landing');
  }, []);

  return <LandlordLandingContainer />;
};

renderInDocumentWithRouter(LandingPage);
