import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Logo } from '@spotahome/ui-library/base';

import colors from '../../../../shared/colors';
import localesShape from '../../shapes/locales';
import { space } from '../../../../shared/spacing';
import { mediaQueries, breakpoints } from '../../../../shared/breakpoints';
import { noop } from '../../../../utils/fn';

import Menu from '../Menu';

const sidebarWidths = {
  collapsed: {
    md: '0px',
    xl: '0px'
  },
  expanded: {
    md: '240px'
  }
};

const menuCollapsedStyle = css`
  transform: translate(-${breakpoints.md}px);
  width: ${sidebarWidths.collapsed.md};

  ${mediaQueries.xl(css`
    width: ${sidebarWidths.collapsed.xl};
  `)};
`;

const menuExpandedStyle = css`
  transform: translate(0);
  width: ${sidebarWidths.expanded.md};
`;

const Container = styled.div`
  background-color: ${colors.darkBlue};
  position: absolute;
  top: 0;
  bottom: 0;

  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.3s ease-in-out;
  transform: translate(0);
  z-index: 10;

  ${({ expanded }) => (expanded ? menuExpandedStyle : menuCollapsedStyle)};

  ${mediaQueries.xl(css`
    position: relative;
    top: auto;
    bottom: auto;
    overflow-x: visible;
    z-index: auto;
    min-width: 240px;
  `)};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${space.x6} ${space.x3} ${space.x2} ${space.x3};
`;

export const SidebarHeader = styled.header`
  display: none;
  ${mediaQueries.xl(css`
    min-height: ${space.x7};
    padding: 0 ${space.x2};
    display: flex;
    justify-content: center;
    align-items: center;
  `)};
`;

const SideBar = ({
  currentRoute = null,
  onSectionClick = noop,
  showTransactionsMenuItem = false,
  showOffersMenuItem = false,
  referralMessage = null,
  referralCaption = null,
  referralLink = null,
  whatsappMessage = null,
  whatsappCaption = null,
  whatsappLink = null,
  showMenuSupportLinksSection = false,
  onPageExit = noop,
  handleWhatsappClick = noop,
  showWhatsappButton = true,
  onCloseMenu,
  menuOpened,
  trans,
  locales
}) => {
  return (
    <Container expanded={menuOpened}>
      <SidebarHeader>
        <Logo size="normal" theme="light" />
      </SidebarHeader>
      <Wrapper>
        <Menu
          trans={trans}
          onClose={onCloseMenu}
          locales={locales}
          currentRoute={currentRoute}
          onSectionClick={onSectionClick}
          showTransactionsMenuItem={showTransactionsMenuItem}
          showOffersMenuItem={showOffersMenuItem}
          referralMessage={referralMessage}
          referralCaption={referralCaption}
          referralLink={referralLink}
          whatsappMessage={whatsappMessage}
          whatsappCaption={whatsappCaption}
          whatsappLink={whatsappLink}
          showMenuSupportLinksSection={showMenuSupportLinksSection}
          onPageExit={onPageExit}
          handleWhatsappClick={handleWhatsappClick}
          showWhatsappButton={showWhatsappButton}
        />
      </Wrapper>
    </Container>
  );
};

SideBar.propTypes = {
  /** Translation function */
  trans: PropTypes.func.isRequired,
  /** Indicates whether the menu is opened */
  menuOpened: PropTypes.bool.isRequired,
  /** Locales object */
  locales: localesShape.isRequired,
  /** current route */
  currentRoute: PropTypes.string,
  /** Callback for the close menu event */
  onCloseMenu: PropTypes.func.isRequired,
  /** Callback for the section click event */
  onSectionClick: PropTypes.func,
  /** Whether to show the transactions menu icon or not*/
  showTransactionsMenuItem: PropTypes.bool,
  /** Whether to show the offers menu icon or not*/
  showOffersMenuItem: PropTypes.bool,
  referralMessage: PropTypes.string,
  referralCaption: PropTypes.string,
  referralLink: PropTypes.string,
  showMenuSupportLinksSection: PropTypes.bool,
  onPageExit: PropTypes.func,
  whatsappMessage: PropTypes.string,
  whatsappCaption: PropTypes.string,
  whatsappLink: PropTypes.string,
  handleWhatsappClick: PropTypes.func,
  showWhatsappButton: PropTypes.bool
};

export default SideBar;
export { sidebarWidths };
