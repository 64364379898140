import { trans, getPagePath, useSoyuzFlag } from '@spotahome/soyuz/client';

import PropTypes from 'prop-types';
import { Typography } from '@spotahome/ui-library/base';

import { useTheme } from '@mui/styles';

import {
  landingLoggedOutRoutes,
  landingLoggedInRoutes
} from '../../../../index';
import localesShape from '../../../../shapes/locales';
import PrecisionHotspot from '../../../PrecisionHotspot';

import * as S from './styles';

const NavMenu = ({
  locales,
  isLoggedIn = true,
  currentRoute = null,
  onSectionClick,
  canShowPlansSection = true,
  highlightPlansSection = true
}) => {
  const muiTheme = useTheme();
  const isLandlordServicesEnabled = useSoyuzFlag(
    'LandlordValueAddedServices'
  ).isOn();

  const handleItemClick = (e, id) => {
    onSectionClick(e, id);
  };

  const shouldHideNavItem = routeId => {
    return (
      routeId === 'transactions' ||
      (routeId === 'plans' && !canShowPlansSection) ||
      (routeId === 'services' && !isLandlordServicesEnabled)
    );
  };

  const shouldHighLightNavItem = routeId => {
    if (routeId === 'plans') {
      return highlightPlansSection;
    }
    return false;
  };

  const routes = isLoggedIn ? landingLoggedInRoutes : landingLoggedOutRoutes;

  return (
    <S.NavContainer theme={muiTheme} data-testid="nav-menu">
      {Object.keys(routes).map(routeKey => {
        const route = routes[routeKey];
        const { path, titleTranslationKey, id } = route;
        const selected = currentRoute === routeKey;

        if (shouldHideNavItem(id)) {
          return null;
        }

        const highlight = shouldHighLightNavItem(id);

        return (
          <S.NavItem
            key={routeKey}
            href={path ?? getPagePath(id, { locale: locales.current })}
            onClick={e => handleItemClick(e, id)}
            data-cy={`${id}-menu-item`}
            data-testid={`${id}-menu-item`}
            theme={muiTheme}
            isCurrentSection={selected}
            isLoggedIn={isLoggedIn}
          >
            {highlight && isLoggedIn && (
              <PrecisionHotspot>
                <Typography.HeadingS>
                  {trans(titleTranslationKey)}
                </Typography.HeadingS>
              </PrecisionHotspot>
            )}
            {highlight && !isLoggedIn && (
              <S.Square>
                <Typography.HeadingS>
                  {trans(titleTranslationKey)}
                </Typography.HeadingS>
              </S.Square>
            )}
            {!highlight && (
              <Typography.HeadingS>
                {trans(titleTranslationKey)}
              </Typography.HeadingS>
            )}
          </S.NavItem>
        );
      })}
    </S.NavContainer>
  );
};

NavMenu.propTypes = {
  locales: localesShape.isRequired,
  currentRoute: PropTypes.string,
  onSectionClick: PropTypes.func.isRequired,
  canShowPlansSection: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  highlightPlansSection: PropTypes.bool
};

export default NavMenu;
