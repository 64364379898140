import axios from 'axios';
import { trans } from '@spotahome/soyuz/client';

import { generateOptions } from './common';

axios.interceptors.response.use(response => {
  const contentTypeHeader =
    response && response.headers && response.headers['content-type'];

  if (contentTypeHeader && !contentTypeHeader.toLowerCase().includes('json')) {
    return Promise.reject(trans('sahc.errors.invalid.response.text'));
  }
  return response;
});

const landlordPanelClient = {
  getListingsGroupedByState(
    maxNumberOfUnpublished = '',
    domain = '',
    cookie = ''
  ) {
    const path = `${domain}/api/listings-by-state?maxUnpublished=${maxNumberOfUnpublished}`;
    const options = generateOptions(cookie);

    return axios.get(path, options);
  },

  getUnpublishedListings(domain = '', cookie = '') {
    const path = `${domain}/api/unpublished-listings`;
    const options = generateOptions(cookie);

    return axios.get(path, options);
  },

  getListingInfo(listingId, domain = '', cookie = '') {
    const path = `${domain}/api/listings/${listingId}`;
    const options = generateOptions(cookie);

    return axios.get(path, options);
  },

  getListingPricing(listingId, domain = '', cookie = '') {
    const path = `${domain}/api/listings/${listingId}/pricing`;
    const options = generateOptions(cookie);

    return axios.get(path, options);
  },

  updateUserInfo(data, domain = '', cookie = '') {
    const path = `${domain}/api/me`;
    const options = generateOptions(cookie);

    return axios.put(path, data, options);
  },

  getListingBookings(listingId, domain = '', cookie = '') {
    const path = `${domain}/api/listings/${listingId}/bookings`;
    const options = generateOptions(cookie);

    return axios.get(path, options);
  },

  updateListingInfo(listingId, data, domain = '', cookie = '') {
    const path = `${domain}/api/listings/${listingId}`;
    const options = generateOptions(cookie);

    return axios.put(path, data, options);
  },

  updateLastAvailabilitiesDate(listingId, domain = '', cookie = '') {
    const path = `${domain}/api/listings/${listingId}/last-availabilities-date`;
    const options = generateOptions(cookie);

    return axios.put(path, null, options);
  },

  async getCity(cityId, domain = '', cookie = '') {
    const path = `${domain}/api/cities/${cityId}`;
    const options = generateOptions(cookie);

    const { data } = await axios.get(path, null, options);
    return data;
  }
};

export default landlordPanelClient;
