import Tracking from '@spotahome/soyuz-tracking';

const LOGOUT_BUTTON_CLICKED = 'LogoutButtonClicked';

export const trackLogoutClicked = (user, locale) => {
  Tracking.segment.trackEvent(LOGOUT_BUTTON_CLICKED, user, {
    user_id: user.id,
    locale
  });
};
