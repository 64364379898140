export const ADD_LISTING_TAG = 'add-listing';
export const ADD_LISTING_BILLS_VALIDATION_ERROR_TAG =
  'add-listing-bills-validation-error';
export const EDIT_PROPERTY_SAVED_CHANGES = 'edit-save-changes';
export const EDIT_PROPERTY_ERROR = 'edit-property-submit-form-error';
export const EDIT_PROPERTY_CANCELLED_CHANGES = 'edit-cancel-changes';
export const EDIT_PROPERTY_UNIT_SEASONAL_PRICE_EDITED = 'edit-seasonal-price';
export const EDIT_PROPERTY_UNIT_PRICE_EDITED = 'edit-price';
export const EDIT_PROPERTY_UNIT_OCCUPANCY_CALENDAR_EDITED = 'edit-calendar';
export const EDIT_PROPERTY_UNIT_OCCUPANCY_ADDED = 'add-occupancy';
export const EDIT_PROPERTY_UNIT_OCCUPANCY_REMOVED = 'delete-occupancy';
export const EDIT_PROPERTY_UNIT_OCCUPANCY_SELECTED_YEAR_CHANGE =
  'selected-year-changed-occupancy';
export const EDIT_PROPERTY_UNIT_OCCUPANCY_TOOLTIP_SHOWN =
  'tooltip-shown-occupancy';
export const EDIT_PROPERTY_UNIT_OCCUPANCY_DATES_SELECTED =
  'dates-selected-occupancy';
export const EDIT_PROPERTY_UNIT_OCCUPANCY_CANCELLATION_REQUESTED =
  'call-us-to-cancel-occupancy';
export const EDIT_PROPERTY_UNIT_OCCUPANCIES_UPDATED = 'occupancies-updated';
export const CREATE_NEW_OFFER_FORM_SUBMITTED =
  'create-new-offer-form-submitted';
export const OFFER_CREATED = 'OfferCreated';
export const CREATE_NEW_OFFER_BUTTON_CLICKED =
  'create-new-offer-button-clicked';
