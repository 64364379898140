export function generateOptions(cookie) {
  const options = {};
  if (cookie) {
    options.headers = {
      cookie
    };
  }

  return options;
}
