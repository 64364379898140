import {
  parse as parseQueryString,
  stringify as stringifyQueryString
} from 'query-string';

function redirectToLogin(delay) {
  const redirect = () => {
    window.location.href = '/api/logout';
  };

  if (delay) {
    setTimeout(redirect, delay);
  } else {
    redirect();
  }
}

function updateQueryParam({
  name,
  value,
  history = window.history,
  location = window.location
}) {
  const queryParams = new URLSearchParams(location.search);
  if (value || value === 0) {
    queryParams.set(name, value);
  } else {
    queryParams.delete(name);
  }

  const queryString = queryParams.toString();
  const newUrl = `${location.pathname}${queryString ? `?${queryString}` : ''}`;
  history.replaceState(null, '', newUrl);
}

export {
  redirectToLogin,
  parseQueryString,
  stringifyQueryString,
  updateQueryParam
};
