import Tracking from '@spotahome/soyuz-tracking';

const REFERRAL_CTA_CLICKED = 'ReferralCTAClicked';

export const trackReferralClicked = (user, locale) => {
  Tracking.segment.trackEvent(REFERRAL_CTA_CLICKED, user, {
    user_id: user.id,
    locale
  });
};
