import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';

import { noop } from '../../../../utils/fn';

import { ToastProvider, ToastContainer } from '../../../../components/Toast';

import Header from '../Header';
import PageContainer from '../PageContainer';
import SideBar from '../SideBar';
import Footer from '../Footer';
import LandlordAppBanner from '../LandlordAppBanner';
import userShape from '../../../../shapes/user';
import localesShape from '../../shapes/locales';

import device from '../../../../shared/device';
import { breakpoints, mediaQueries } from '../../../../shared/breakpoints';
import colors from '../../../../shared/colors';

import { clearChatUserInfo, setChatUserInfo } from './livechat';
import {
  showAppBanner,
  deactivateAppBannerSession,
  clearShowAppBannerSession
} from './landlordAppBanner';

const AppContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;

  ${mediaQueries.xl(css`
    flex-direction: row;
  `)};
`;

const MainContainer = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.backgroundColor};
  overflow: auto;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: auto;

  ${mediaQueries.xl(css`
    overflow: initial;
  `)};
`;

const MAIN_CONTAINER_ID = 'mainContainer';

const Layout = ({
  currentRoute = null,
  onSectionClick = noop,
  showTransactionsMenuItem = false,
  showOffersMenuItem = false,
  forceShowAppBanner = false,
  onClickBannerAppHeader = () => {},
  backgroundColor = colors.athensGray,
  onPageExit = noop,
  referralMessage = null,
  referralCaption = null,
  referralLink = null,
  whatsappMessage = null,
  whatsappCaption = null,
  whatsappLink = null,
  showMenuSupportLinksSection = false,
  handleWhatsappClick = noop,
  showWhatsappButton = true,
  user,
  children,
  locales,
  trans,
  onLanguageChange,
  onLogOutClick
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(
    forceShowAppBanner || showAppBanner
  );

  const isDesktop = useMediaQuery({ minWidth: breakpoints.xl });
  const isWebView = device.isWebView();

  useEffect(() => {
    setChatUserInfo(user);
  }, [user]);

  const handleMenuButtonClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const handleLogout = event => {
    clearChatUserInfo();
    clearShowAppBannerSession();
    onLogOutClick(event);
  };

  const handleBannerClose = () => {
    deactivateAppBannerSession();
    setIsBannerOpen(false);
  };

  const sidebarProps = {
    trans,
    menuOpened: isDesktop ? true : isMenuOpen,
    onCloseMenu: handleCloseMenu,
    locales,
    currentRoute,
    onSectionClick,
    showTransactionsMenuItem,
    showOffersMenuItem,
    referralMessage,
    referralCaption,
    referralLink,
    whatsappMessage,
    whatsappCaption,
    whatsappLink,
    showMenuSupportLinksSection,
    handleWhatsappClick,
    showWhatsappButton
  };

  const headerProps = {
    user,
    onLogout: handleLogout,
    logoutTitle: trans('sahc.header.logout.title'),
    logoutButtonText: trans('sahc.header.logout.text'),
    onMenuButtonClick: handleMenuButtonClick,
    backgroundColor,
    isDesktop
  };

  return (
    <ToastProvider>
      <AppContainer>
        {!isWebView && !isDesktop && (
          <LandlordAppBanner
            isOpen={isBannerOpen}
            closable
            onRequestClose={handleBannerClose}
            trans={trans}
            onClickBannerAppHeader={onClickBannerAppHeader}
          />
        )}

        {isDesktop ? (
          <SideBar onPageExit={onPageExit} {...sidebarProps} />
        ) : (
          <Header {...headerProps} />
        )}

        <MainContainer
          id={MAIN_CONTAINER_ID}
          isDesktop={isDesktop}
          backgroundColor={backgroundColor}
        >
          {isDesktop ? (
            <Header {...headerProps} />
          ) : (
            <SideBar onPageExit={onPageExit} {...sidebarProps} />
          )}

          <ToastContainer />
          <PageWrapper>
            <PageContainer isMenuOpen={isMenuOpen}>{children}</PageContainer>

            {!isWebView && (
              <Footer
                trans={trans}
                locales={locales}
                onLanguageChange={onLanguageChange}
                currentRoute={currentRoute}
                onPageExit={onPageExit}
              />
            )}
          </PageWrapper>
        </MainContainer>
      </AppContainer>
    </ToastProvider>
  );
};

Layout.propTypes = {
  /** Translation function */
  trans: PropTypes.func.isRequired,
  /** User object */
  user: userShape.isRequired,
  /** Locales object */
  locales: localesShape.isRequired,
  /** current route */
  currentRoute: PropTypes.string,
  /** Callback for the section click event*/
  onSectionClick: PropTypes.func,
  /** Callback for the logout click event */
  onLogOutClick: PropTypes.func.isRequired,
  /** Callback for language change event */
  onLanguageChange: PropTypes.func.isRequired,
  /** Content for the layout */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  /** Whether to show the transactions menu icon or not*/
  showTransactionsMenuItem: PropTypes.bool,
  /** Whether to show the offers menu icon or not*/
  showOffersMenuItem: PropTypes.bool,
  /** Forces the Landlord App banner to be shown */
  forceShowAppBanner: PropTypes.bool,
  /** Callback passed to the Header component */
  onClickBannerAppHeader: PropTypes.func,
  /** Layout background color */
  backgroundColor: PropTypes.string,
  /** Callback for page exit event */
  onPageExit: PropTypes.func,
  referralMessage: PropTypes.string,
  referralCaption: PropTypes.string,
  referralLink: PropTypes.string,
  whatsappMessage: PropTypes.string,
  whatsappCaption: PropTypes.string,
  whatsappLink: PropTypes.string,
  showMenuSupportLinksSection: PropTypes.bool,
  handleWhatsappClick: PropTypes.func,
  showWhatsappButton: PropTypes.bool
};

export default Layout;
export { MAIN_CONTAINER_ID };
