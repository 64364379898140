import { getKey } from '../../../utils/object';

import SecurityDeposit from '../../model/SecurityDeposit';

export const selectDetails = state => ({
  address: state.address,
  area: state.area,
  studioDistribution: state.studioDistribution,
  bathrooms: state.bathrooms,
  units: state.units,
  minimumStaying: state.minimumStaying
});

export const selectId = state => state.id;
export const selectDraftId = state => state.draftId;
export const selectType = state => state.type;
export const selectCityId = state => state.cityId || state.location?.cityId;
export const selectAmenities = state => state.amenities;
export const selectFeatures = state => state.features;
export const selectRent = state => ({
  monthlyRent: state.monthlyRent,
  securityDeposit: state.securityDeposit,
  minimumStaying: state.minimumStaying
});
export const selectBills = state => state.bills || [];

export const selectBedroomAmenities = (state, props = {}) =>
  getKey(state, `bedrooms[${props.bedroomNumber - 1}].amenities`, {});

export const selectBedrooms = state => getKey(state, 'bedrooms', []);

export const selectRules = state => state.rules;

export const selectBedroom = (state, bedroomNumber) =>
  getKey(state, `bedrooms[${bedroomNumber - 1}]`, {});

export const selectBedroomsAmount = state => selectBedrooms(state).length;

export const newDateFromKey = (state, key) => {
  return getKey(state, key) ? new Date(getKey(state, key)) : null;
};

export const selectAvailableFrom = state => {
  return newDateFromKey(state, 'availableFrom');
};

export const selectBedroomAvailableFrom = (state, bedroomNumber) => {
  return newDateFromKey(state, `bedrooms[${bedroomNumber - 1}].availableFrom`);
};
export const selectBedroomRent = (state, bedroomNumber) => {
  const securityDeposit = getKey(
    selectBedroom(state, bedroomNumber),
    'securityDeposit',
    SecurityDeposit()
  );
  const monthlyRent = getKey(
    selectBedroom(state, bedroomNumber),
    'monthlyRent'
  );
  const minimumStaying = getKey(
    selectBedroom(state, bedroomNumber),
    'minimumStaying'
  );
  return { monthlyRent, securityDeposit, minimumStaying };
};

export const selectUnitsTotal = state => getKey(state, 'units.total');

export const selectUnitsRentable = state => getKey(state, 'units.rentable');

export const selectHomeCheckerAppointment = state =>
  getKey(state, 'homeCheckerAppointment');

export const selectAddress = state => state.address;

export const selectSkipPhotos = state => state.skipPhotos;
export const isPublishable = state => !selectSkipPhotos(state);

export const selectBedroomPhotos = (state, props = {}) =>
  getKey(state, `bedrooms[${props.bedroomNumber - 1}].photos`, []);
export const selectAllBedroomPhotosWithUnitId = state =>
  getKey(state, 'bedrooms')
    .map((bedroom, bedroomIndex) =>
      bedroom.photos.map(photo => ({ ...photo, unitId: bedroomIndex }))
    )
    .flat();
export const selectBedroomPhotosChecksum = (state, props = {}) =>
  selectBedroomPhotos(state, props).map(photo => getKey(photo, 'checksum', ''));
export const selectBedroomTotalPhotos = (state, props = {}) =>
  selectBedroomPhotos(state, props).length;

export const selectPhotos = state => getKey(state, 'photos', []);
export const selectPhotosChecksum = state =>
  selectPhotos(state).map(photo => getKey(photo, 'checksum', ''));
export const selectTotalPhotos = state => selectPhotos(state).length;
