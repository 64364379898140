import Tracking from '@spotahome/soyuz-tracking';

import {
  PENDING_BOOKINGS_BANNER_SHOWN,
  PENDING_BOOKINGS_BANNER_CLOSED,
  PENDING_BOOKINGS_BANNER_BUTTON_CLICKED
} from '../segment/events';

const BANNER_CLICKED = 'BannerClicked';

export const handleBannerClicked = (user, locale, banner) => {
  Tracking.segment.trackEvent(BANNER_CLICKED, user, {
    locale,
    banner
  });
};

export const trackPendingBookingsBannerShown = user => {
  Tracking.hotjar.recordTag([PENDING_BOOKINGS_BANNER_SHOWN]);
  Tracking.segment.trackEvent(PENDING_BOOKINGS_BANNER_SHOWN, user, {});
};

export const trackPendingBookingsBannerClosed = user => {
  Tracking.hotjar.recordTag([PENDING_BOOKINGS_BANNER_CLOSED]);
  Tracking.segment.trackEvent(PENDING_BOOKINGS_BANNER_CLOSED, user, {});
};

export const trackPendingBookingsBannerButtonClicked = user => {
  Tracking.hotjar.recordTag([PENDING_BOOKINGS_BANNER_BUTTON_CLICKED]);
  Tracking.segment.trackEvent(PENDING_BOOKINGS_BANNER_BUTTON_CLICKED, user, {});
};
