import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const ListingsCountContext = createContext(null);

export const ListingsCountProvider = ({ children }) => {
  const [listingsCount, setListingsCount] = useState(null);

  return (
    <ListingsCountContext.Provider value={{ listingsCount, setListingsCount }}>
      {children}
    </ListingsCountContext.Provider>
  );
};

ListingsCountProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useListingsCountContext = () => useContext(ListingsCountContext);
