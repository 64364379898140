import InvalidValueError from '../error/InvalidValueError';

const BillOptions = {
  INCLUDED: 'included',
  PAY_TO_LANDLORD: 'payToLandlord',
  PAY_TO_PROVIDER: 'payToProvider'
};

export const getValues = () => Object.values(BillOptions);

export const isValid = value => getValues().includes(value);

export const validate = value => {
  if (!isValid(value)) {
    throw new InvalidValueError('Invalid bill');
  }
};

export default BillOptions;
