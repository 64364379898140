import InvalidValueError from '../error/InvalidValueError';

const Types = {
  WHOLE: 'whole',
  SHARED: 'shared',
  ROOM_SHARED: 'roomShared',
  STUDIO: 'studio',
  RESIDENCE: 'residence'
};

export const isWhole = type => Types.WHOLE === type;
export const isShared = type => Types.SHARED === type;
export const isRoomShared = type => Types.ROOM_SHARED === type;
export const isStudio = type => Types.STUDIO === type;
export const isResidence = type => Types.RESIDENCE === type;

export const isValid = value => Object.values(Types).includes(value);

export const validate = value => {
  if (!isValid(value)) {
    throw new InvalidValueError('Invalid property type');
  }
};

export const mapPropertyTypeToListingType = property => {
  const TypesMap = {
    ROOM_SHARED: 'roomShared',
    STUDIO: 'studio',
    APARTMENT: 'apartment'
  };

  if (isShared(property.type)) {
    return TypesMap.ROOM_SHARED;
  }

  return property?.units?.total === 1 && property?.studioDistribution === true
    ? TypesMap.STUDIO
    : TypesMap.APARTMENT;
};

export default Types;
