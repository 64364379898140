import { useState, useEffect } from 'react';

export default function usePlansSection() {
  const storageKey = `plans-section-visited`;

  const [plansSectionVisited, internalSetPlansSectionVisited] = useState(false);

  const setPlansSectionVisited = value => {
    internalSetPlansSectionVisited(value);
    localStorage.setItem(storageKey, value);
  };

  useEffect(() => {
    internalSetPlansSectionVisited(localStorage.getItem(storageKey) || false);
  }, []);

  return [plansSectionVisited, setPlansSectionVisited];
}
