const ALL_IN_PLAN = 'allIn';
const SELECT_PLAN = 'select';
const PREMIUM_PLAN = 'premium';
const PLUS_PLAN = 'plus';

export const PAID_PLANS = [ALL_IN_PLAN, SELECT_PLAN, PREMIUM_PLAN, PLUS_PLAN];

export const isPaidPlan = plan => PAID_PLANS.includes(plan);

export const planCanBeUpgradedInCity = (plan, city) =>
  plan === 'standard' && !['istanbul'].includes(city);
